import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectMronda (Vue, filter, search, sorter, page, idpuestoServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idpuesto_servicio', idpuestoServicio)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
        .addILike('servicio_descripcion', search)
        .addILike('puesto_servicio_descripcion', search)
        .addILike('cliente_nombre', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    const resp = await Vue.$api.call('mronda.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectMrondaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idmronda', pks)
    const resp = await Vue.$api.call('mronda.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async crearRonda (Vue, idmronda, idvigilante) {
    const ronda = await Vue.$api.call(
      'ronda.insert', { values: { idmronda, idvigilante } }
    )
    return ronda.data.result.dataset[0]
  },
}
