<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron rondas"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idmronda"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <mronda-list-item
          :item="slotProps.item"
          :index="slotProps.index"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { listPageMixin } from '@/mixins/listPageMixin'
import { get, sync } from 'vuex-pathify'
import Data from './MrondaListData'
import MrondaListItem from './components/MrondaListItem'
import { NextRouteNavigate } from '@/router'

const pageStoreName = 'pagesMrondaList'

export default {
  components: {
    MrondaListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    nextRoute: get(`${pageStoreName}/nextRoute`),
    idpuestoServicio: get('presencia/idpuestoServicio'),
    vigilanciaIdronda: sync('vigilancia/idronda'),
    usuarioIdvigilante: get('usuario/idvigilante'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Rondas programadas'
      if (this.vigilanciaIdronda) {
        this.$alert.showSnackbarWarning(
          'Tienes una ronda en curso',
          { persistent: true, actions: [{ text: 'Continuar', flat: true, handler: this.clickContinuarRonda }], }
        )
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMronda(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.idpuestoServicio
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectMrondaRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idmronda')
      } finally {
        this.loadingData = false
      }
    },
    async clickListItem (item) {
      if (this.nextRoute && !this.vigilanciaIdronda && _.has(this.nextRoute.params, 'idronda')) {
        const ronda = await Data.crearRonda(
          this, item.data.idmronda, this.usuarioIdvigilante
        )
        this.vigilanciaIdronda = ronda.idronda
        await this.setStoreProperty('nextRoute@params.idronda', ronda.idronda)
        NextRouteNavigate(this.nextRoute)
      } else {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'rondas__mronda-view',
          params: {
            idmronda: item.data.idmronda,
          },
        })
      }
    },
    clickContinuarRonda () {
      this.$appRouter.push({
        name: 'rondas__ronda-realizar',
        params: {
          idronda: this.vigilanciaIdronda,
        },
      })
    },
  }
}
</script>
